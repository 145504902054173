import { useEffect, useState } from 'react';
import { Unity, useUnityContext } from "react-unity-webgl";
import { Loader } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { useParams } from 'react-router-dom';

function UnityLoader() {

    function handleCacheControl(url) {
        // let url: any = process.env.REACT_APP_API_URL;
        if (url && (url.match(/\.data/) || url.match(/\.bundle/))) {
          return "must-revalidate";
        }
        if (url && (url.match(/\.mp4/) || url.match(/\.wav/))) {
          return "immutable";
        }
        return "no-store";
    }

    const { shipmentId, userId, type } = useParams();
    let [id, setId] = useState("");
    let [getUserId, setUserId] = useState("");
    let [getType, setType] = useState("web");
    
    const { unityProvider, isLoaded, sendMessage } = useUnityContext({
        loaderUrl: "build/CargoContainer.loader.js",
        dataUrl: "build/CargoContainer.data",
        frameworkUrl: "build/CargoContainer.framework.js",
        codeUrl: "build/CargoContainer.wasm",
        cacheControl: handleCacheControl
    });

    useEffect(() => {
        setId(shipmentId ? shipmentId : 'shipmentId');
        setUserId(userId ? userId : 'userId');
        setType(type ? type : 'web');
    }, []);
    
    useEffect(() => {
        sendMessage("Shipment", "Shipment", id);
        sendMessage("Shipment", "User", getUserId);
        sendMessage("Shipment", "Type", getType);
    }, [isLoaded]);

    return (
    <div className="App">
        {!isLoaded && (
        // <h2>Loading Application... {Math.round(loadingProgression * 100)}%</h2>
        <h2 style={{marginTop: '20px'}}>Loading Application <Loader size="md" /></h2>
        )}
        <Unity
        unityProvider={unityProvider}
        style={{ width: '100%', height: isLoaded ? '100vh' : '90vh', visibility: isLoaded ? "visible" : "hidden" }}
        />
    </div>
    );
}

export default UnityLoader;