import { Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import UnityLoader from './Components/UnityLoader';

function App() {
  return(
    <Routes>
      <Route path={`/:shipmentId/:userId/:type`} element={<UnityLoader />} />
      <Route path='*' element={<Navigate to="/:shipmentId/:userId/:type" />} />
    </Routes>
  )
}

export default App;
